// src/templates/whitepaper-template.js
import React from 'react';
import { graphql } from 'gatsby';
import Helmet from '../components/Helmet';
import CustomPageWrapper from '../components/CustomPageWrapper/CustomPageWrapper';
import Navbar from '../landing/components/Navbar';
import Info from '../landing/sections/Info';
import SimpleHeader from '../sections/whitepapers/sections/SimpleHeader';

const WhitepaperTemplate = ({ data }) => {
  const whitepaper = data.whitepapersJson;

  const title = 'Smarttie | Whitepapers';

  const description =
    'Access in-depth research on AI and Data from Smarttie. Discover the latest trends, technologies, and innovations shaping the future of artificial intelligence and data analytics.';

  const banner = true;

  const url = 'https://www.smarttie.ca/whitepapers/';

  const headData = {
    title,
    description,
    banner,
    url,
  };

  return (
    <>
      <Helmet data={headData} />

      <CustomPageWrapper>
        <div className='text-white'>
          <Navbar top='20' />
          <div className='relative -top-[56px] sm:-top-[72px]'>
            <Info />

            <SimpleHeader whitepaper={whitepaper} />
          </div>
        </div>
      </CustomPageWrapper>
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    whitepapersJson(id: { eq: $id }) {
      id
      category
      date(fromNow: true)
      description
      jsonId
      title
      content {
        description
        subcontent
        title
      }
    }
  }
`;

export default WhitepaperTemplate;
